<style lang="scss" src="./home-slider.component.scss"></style>

<template>
  <div class="home-slider">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="home-slider__content">
            <div class="home-slider__content_slide">
              <slider-custom-component :showText="showTextAbove" :images="carousel"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js" src="./home-slider.component.js"></script>
