<style lang="scss" src="./slider-progress.component.scss"></style>

<template>
  <div class="progress">
    <div class="progress-content">
      <div class="progress-content_first">
        <span>01</span>
      </div>
      <div class="progress-content_red" :style="{'width': count + '%'}"></div>
      <div class="progress-content_last">
        <span v-if="this.images.length >= 10">{{this.images.length}}</span>
        <span v-else>{{"0" + this.images.length}}</span>
      </div>
    </div>
  </div>
</template>

<script lang="js" src="./slider-progress.component.js"></script>
