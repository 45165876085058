import BestOfferComponent from "@/views/home/components/best-offer/best-offer.component.vue";
import HomeSliderComponent from "@/views/home/components/home-slider/home-slider.component.vue";
import HomeAboutComponent from "@/views/home/components/home-about/home-about.component.vue";

export default {
    name: 'home-component',
    components: {
        'best-offer-component': BestOfferComponent,
        'home-slder-component': HomeSliderComponent,
        'home-about-component': HomeAboutComponent
    },
    data(){
      return {
          lists: [
              {
                  id: 1,
                  count: '1.',
                  title: "Leo tortor feugiat.",
                  text: "Ornare egestas dui platea donec amet, urna. Facilisis dolor fames pharetra vel."
              },
              {
                  id: 2,
                  count: '2.',
                  title: "Leo tortor feugiat.",
                  text: "Ornare egestas dui platea donec amet, urna. Facilisis dolor fames pharetra vel."
              },
              {
                  id: 3,
                  count: '3.',
                  title: "Leo tortor feugiat.",
                  text: "Ornare egestas dui platea donec amet, urna. Facilisis dolor fames pharetra vel."
              },
              {
                  id: 4,
                  count: '4.',
                  title: "Leo tortor feugiat.",
                  text: "Ornare egestas dui platea donec amet, urna. Facilisis dolor fames pharetra vel."
              },
          ]
      }
    }
}
