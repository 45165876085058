export default {
    name: "slider-item.component",
    props:{
        slide: {
            type: String,
            required: true
        },
        aboveTitle: {
            type: String,
        },
        aboveSubTitle: {
            type: String
        },
        aboveText: {
            type: String
        },
        showContentText: {
            type: Boolean,
            required: true,
            default: false
        },
        currentSlide: {
            type: Number,
            required: true
        },
        index:{
            type: Number
        },
        direction:{
            type: String
        }
    },
    emits:['mouseenter','mouseout'],
    computed:{
        transitionEffect(){
            return this.direction === "right" ? "slide-out" : "slide-in";
        }
    }
}
