import {mapGetters} from "vuex";

export default {
    name: "home-about-component",
    props: ['lists'],
    data(){
        return{

        }
    },
    computed:{
        ...mapGetters({
            features: 'features/_getHomeAbouts',
            featureFile: 'features/_getHomeAboutsFile',
            local: '_getLocal',
        })
    },
    created() {
        this.$store.dispatch('features/getAbouts')
    }
}
