import {mapGetters} from "vuex";

export default {
    name: "best-offer.component.vue",
    data(){
        return{

        }
    },
    computed: {
        ...mapGetters({
            suggestions: 'suggestions/_getSuggestions',
            suggestObject: 'suggestions/_getSuggest'
        })
    },
    created() {
        this.$store.dispatch('suggestions/getSuggestions')
    }
}
