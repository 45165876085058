<style lang="scss" src="./slider-custom.component.scss"></style>

<template>
  <div class="slider">
    <slider-control-component @prev="prev" @next="next"/>
    <slider-progress-component :currenSlide="currentSlide" :images="images"/>
    <scroll-button-component/>
    <div class="slider-section">
      <slider-item-component
          v-for="(image,index) in images"
          :key="`item-${index}`"
          :slide="image.fileUrl"
          :aboveTitle="image.title"
          :aboveSubTitle="image.subTitle"
          :aboveText="image.description"
          :currentSlide="currentSlide"
          :index="index"
          :showContentText="showText"
          :direction="direction"
          @mouseover="stopSlideTimer"
          @mouseleave="startSlideTimer"
          />
    </div>
  </div>
</template>

<script lang="js" src="./slider-custom.component.js"></script>
