<style lang="scss" src="./slider-control.component.scss"></style>

<template>
  <div class="slide-btn">
    <div class="slide-btn_control left" @click="$emit('prev')">
      <div class="slide-btn_control__desc">
        <FontAwesomeIcon icon="chevron-up"/>
      </div>
      <div class="slide-btn_control__mobile">
        <FontAwesomeIcon icon="chevron-left"/>
      </div>
    </div>
    <div class="slide-btn_control right" @click="$emit('next')">
      <div class="slide-btn_control__desc">
        <FontAwesomeIcon icon="chevron-down"/>
      </div>
      <div class="slide-btn_control__mobile">
        <FontAwesomeIcon icon="chevron-right"/>
      </div>
    </div>
  </div>
</template>

<script lang="js" src="./slider-control.component.js"></script>
