<style lang="scss" src="./scroll-button.component.scss"></style>
<template>
  <div class="scroll-btn">
    <div class="scroll-btn_desc">
      <a href="#about">
        <FontAwesomeIcon icon="arrow-down"/>
      </a>
    </div>
    <div class="scroll-btn_mobile">
      <a href="#about">
        <FontAwesomeIcon icon="arrow-down"/>
      </a>
    </div>
  </div>
</template>

<script lang="js" src="./scroll-button.component.js"></script>
