export default {
    name: "slider-progress-component",
    props:['currenSlide','images'],
    data(){
        return{
        }
    },
    computed:{
        currenSlide(){
            return this.currenSlide
        },
        count: function (){
            return ((this.currenSlide + 1) / this.images.length ) * 100
        }
    }
}
