import SliderCustomComponent from "@/core/shared/slider-custom/slider-custom.component.vue";
import Eventbus from "@/eventbus/eventbus";
import {mapGetters} from "vuex";

export default {
    name: "home-slider-component",
    components: {
        'slider-custom-component': SliderCustomComponent,
    },
    data(){
        return{
            showTextAbove: true,
        }
    },
    computed: {
        ...mapGetters({
            carousel: 'carousel/_getCarousel'
        })
    },
    methods:{
        next(){
            Eventbus.emit('next')
        },
        prev(){
            Eventbus.emit('prev')
        }
    },
    created() {
        this.$store.dispatch("carousel/getCarousel")
    }
}
