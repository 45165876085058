<style lang="scss" src="./best-offer.component.scss"></style>

<template>
  <div class="offer pb-90">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-6 col-12 items">
          <div class="offer_content first">
            <div class="offer_content-title">
              <h4>{{suggestObject.title}}</h4>
            </div>
            <div class="offer_content-text">
              <p class="pt-12 mt-18">{{suggestObject.description}}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-12 items" v-for="(suggest, index) in suggestions" :key="index">
          <div class="offer_content">
            <input type="checkbox">
            <h6>{{suggest.title}}</h6>
            <p class="mt-10">{{suggest.description}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js" src="./best-offer.component.js"></script>
