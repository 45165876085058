import SliderItemComponent from "@/core/shared/slider-custom/component/slider-item/slider-item.component.vue";
import Eventbus from "@/eventbus/eventbus";
import ScrollButtonComponent from "@/core/shared/slider-custom/component/scroll-button/scroll-button.component.vue";
import SliderControlComponent from "@/core/shared/slider-custom/component/slider-control/slider-control.component.vue";
import SliderProgressComponent from "@/core/shared/slider-custom/component/slider-progress/slider-progress.component.vue";
export default {
    name: "slider-custom.component",
    components: {
        'slider-item-component': SliderItemComponent,
        'scroll-button-component': ScrollButtonComponent,
        'slider-control-component': SliderControlComponent,
        'slider-progress-component': SliderProgressComponent
    },
    props:{
        images:{
            type: Array,
            required: true
        },
        textList: {
            type: Array
        },
        showText: {
            type: Boolean,
            required: true
        }
    },
    data(){
        return{
            currentSlide: 0,
            slideInterval: null,
            direction: "right"
        }
    },
    methods: {
        setCurrentSlide(index){
            this.currentSlide = index;
        },
        prev(){
            const index = this.currentSlide > 0 ? this.currentSlide - 1 : this.images.length - 1;
            this.setCurrentSlide(index);
            this.direction = "left";
            this.startSlideTimer();
        },
        next(){
            const index = this.currentSlide < this.images.length - 1 ? this.currentSlide + 1 : 0;
            this.setCurrentSlide(index);
            this.direction = "right";
            this.startSlideTimer();
        },
        startSlideTimer(){
            this.stopSlideTimer();
            this.slideInterval = setInterval(() => {
                this.next()
            },3000)
        },
        stopSlideTimer(){
            clearInterval(this.slideInterval)
        }
    },
    mounted() {
        this.startSlideTimer()
        Eventbus.on('next',this.next)
        Eventbus.on('prev',this.prev)
    },
    beforeUnmount() {
        this.stopSlideTimer();
    }
}
