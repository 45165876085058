<style lang="scss" src="./home-about.component.scss"></style>
<template>
  <div id="about" class="home-about pt-90">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-9 col-12" v-if="local && local.homeAbout">
          <div class="home-about_title">
            <h1>{{local.homeAbout.title}}</h1>
            <p class="pt-9">{{local.homeAbout.description}}</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-3 col-12" v-if="local && local.homeAbout">
          <div class="home-about_link">
            <router-link to="/about">
              {{local.homeAbout.linkText}}
              <img class="ml-5 p-7" :src="require('../../../../assets/images/go-to.svg')" alt="go">
            </router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="row">
            <div class="col-lg-6 col-md-6" v-for="(list,index) in features" :key="index">
              <div class="home-about_lists mt-60">
                <h1>{{index + 1 + "."}}</h1>
                <h5 class="mt-34">{{list.title}}</h5>
                <p class="mt-10">{{list.description}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="home-about_mobile__link">
            <router-link to="/about">
              See all
              <img class="ml-5 p-7" :src="require('../../../../assets/images/go-to.svg')" alt="go">
            </router-link>
          </div>
          <div class="home-about_images mt-25">
            <div class="home-about_images__content" v-for="(img, index) of featureFile" :key="index">
              <img :src="img" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js" src="./home-about.component.js"></script>

