<style lang="scss" src="./slider-item.component.scss"></style>
<template>
  <div class="slide" @mouseover="$emit('mouseover')"
       @mouseleave="$emit('mouseleave')">
    <transition :name="transitionEffect">
      <div :class="showContentText ? 'slide-item' : 'slide-full'"
           v-show="currentSlide === index">
        <div v-show="showContentText" class="slide-item_content pl-36 pt-70 pr-32 pb-95">
          <h5>{{aboveTitle}}</h5>
          <h5>{{aboveSubTitle}}</h5>
          <p class="pt-27">{{aboveText}}</p>
        </div>
        <div :class="showContentText ? 'slide-item__half' : 'slide-full__picture'">
          <img :src="slide" alt="">
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="js" src="./slider-item.component.js"></script>
